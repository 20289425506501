import axios from '../boot/axiosApi';
import { convertTimeFromAmpm } from '@/utils';
import store from '@/store/index';

const initialState = {
  list: [],
  item: {},
  activeVenueId: 0,
  error: '',
  loading: false,
  search: null,
  languages: [],
  deliveryMethods: [],
  idNames: [],
  menu: {
    woltInstances: [],
    boltInstances: [],
    fudyInstances: [],
  },
  disableMenuItem: [],
  lastUpdateTime: '',
  licensesList: [],
  licenseActions: [],
  lastMenuVersion: '',
  menuVersion: '',
  woltsInCorrectDiscounts: JSON.parse(localStorage.getItem('woltsInCorrectDiscounts') || '[]'),
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setLastMenuVersion(state, result) {
      state.lastMenuVersion = result;
    },
    setWoltsInCorrectDiscounts(state, result) {
      localStorage.setItem('woltsInCorrectDiscounts', JSON.stringify(result));
      state.woltsInCorrectDiscounts = result;
    },
    setNewMenuVersion(state, result) {
      state.menuVersion = result;
    },
    setLastUpdateTime(state, result) {
      state.lastUpdateTime = result;
    },
    setLoading(state, result) {
      state.loading = !state.loading;
    },
    setLoadingBoolean(state, result) {
      state.loading = result;
    },
    setLoadingVenueId(state, result) {
      state.loadingVenueId = result;
    },
    errorInfo(state, error) {
      state.error = error;
    },
    setList(state, result) {
      state.list = result;
    },
    setMenu(state, result) {
      state.menu = result;
    },
    setEnabladDish(state, { dishId, provider, menuIndex }) {
      function findDish(dish) {
        if (dish.id === dishId) {
          console.log('work');
          dish.isEnabled = !dish.isEnabled;
        } else {
          if (dish.children) {
            dish.children.forEach(item => {
              findDish(item);
            });
          } else {
            return;
          }
        }
      }
      findDish(state.menu[provider][menuIndex].children[0]);
    },
    setEnabledModifier(state, { dishId, provider, menuIndex }) {
      function findDish(dish) {
        const isMatchDish = dish.id
          ? dish.id.split('_').at(-1) === dishId.split('_').at(-1) && dish.id[0] !== 'i'
          : false;
        if (isMatchDish) {
          dish.isEnabled = !dish.isEnabled;
        } else {
          if (dish.children) {
            dish.children.forEach(item => {
              findDish(item);
            });
          } else {
            return;
          }
        }
      }
      findDish(state.menu[provider][menuIndex].children[0]);
    },
    setEnabledComboComponent(state, { dishId, menuIndex }) {
      function findDish(dish) {
        const isMatchDish = dish.id
          ? dish.id.split('_').at(-1) === dishId.split('_').at(-1) && dish.id[0] !== 'i'
          : false;
        if (isMatchDish) {
          dish.isEnabled = !dish.isEnabled;
        } else {
          if (dish.children) {
            dish.children.forEach(item => {
              findDish(item);
            });
          } else {
            return;
          }
        }
      }
      findDish(state.menu.children[menuIndex]);
    },
    toggleDisableStatus(state, { provider, menuIndex, categoryIndex, status }) {
      state.menu[provider][menuIndex].children[0].children[categoryIndex].children.forEach(dish => {
        if (!dish.isBrokenCombo && !dish.isDisabledInRk) dish.isEnabled = status;
      });
    },
    setItem(state, result) {
      state.item = result;
    },
    fetchDelete(state, idDelete) {
      state.list = state.list.filter(item => item.id !== idDelete);
    },
    clearError(state) {
      state.error = '';
    },
    clearState(state) {
      Object.assign(state, initialState);
    },
    clearList(state) {
      state.list = [];
    },
    setActiveVenueId(state, id) {
      state.activeVenueId = id;
    },
    setSearch(state, result) {
      state.search = result;
    },
    setLicensesList(state, data) {
      state.licensesList = data;
    },
    setLicenseActions(state, data) {
      state.licenseActions = data;
    },
    clearSearch(state) {
      state.search = null;
    },
    addToList(state, item) {
      state.list.push(item);
    },
    setLanguages(state, arr) {
      state.languages = arr;
    },
    setDeliveryMethods(state, arr) {
      state.deliveryMethods = arr;
    },
    setIdNames(state, arr) {
      state.idNames = arr;
    },
    editVenueProp(state, data) {
      state.list.forEach(el => (el.id === data.venueId ? (el.status = data.status) : {}));
    },
    //get field to edit
    checkAndEditVenue(state, data) {
      //data - venueId, fieldName, value of field
      if (data.venueId === state.item.id) {
        if (data.field === 'availablities') {
          state.item = {
            ...state.item,
            availablities: { ...data.availablities },
          };
        } else if (data.field === 'schedule' || data.field.includes('schedule')) {
          let newField = data.field.split('.')[0];
          if (data.field.split('.')[1]) state.item[newField][data.field.split('.')[1]] = [...data.value];
          else state.item[newField] = [...data.value];
        }
      }
    },
    setVenueMenuLoading(state, id) {
      state.list = state.list.map(el => {
        if (el.id === id) {
          return { ...el, isLoading: true };
        }
        return el;
      });
    },
    unsetVenueMenuLoading(state, id) {
      state.list = state.list.map(el => {
        if (el.id === id) {
          return { ...el, isLoading: false };
        }
        return el;
      });
    },
  },
  actions: {
    fetchData({ commit }, url = '/venues') {
      commit('setLoading');
      return axios
        .get(url)
        .then(({ data }) => {
          commit('setList', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error?.alert[0];
        });
    },
    fetchMenu({ commit }, id) {
      commit('setLoadingBoolean', true);
      return axios
        .get(`/Menu?venueId=${id}`)
        .then(({ data }) => {
          console.log('changeMenu', data);
          localStorage.setItem('menuVersion', data.menuVersion);
          commit('setMenu', data);
          commit('setLoadingBoolean', false);
          commit('setLoadingVenueId', id);
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoadingBoolean', false);
          return error.alert[0];
        });
    },
    changeMenu({ commit }, data) {
      commit('setLoadingBoolean', true);
      return axios({
        method: 'POST',
        url: '/Menu',
        data: data,
      })
        .then(({ data }) => {
          commit('setMenu', data);
          commit('setLoadingBoolean', false);
          console.log('fetchMenu', data);
          localStorage.setItem('newMenuVersion', data.menuVersion);
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoadingBoolean', false);
          return error.alert[0];
        });
    },
    downloadMenu({ commit }, data) {
      commit('setLoading');
      return axios({
        method: 'POST',
        url: '/Menu/Download',
        data: data,
      })
        .then(({ data }) => {
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    fetchItem({ commit }, id) {
      commit('setLoadingBoolean', true);
      return axios
        .get(`/venues/${id}`)
        .then(data => {
          commit('setItem', data.data);
          commit('setLoadingBoolean', false);
          return data.data;
        })
        .catch(({ response }) => {
          let error = response?.data?.errors;
          commit('errorInfo', error);
          commit('setLoadingBoolean', false);
          return error.alert[0];
        });
    },

    addItemToDB({ commit }, data) {
      commit('setLoading');
      return axios({
        method: 'POST',
        url: '/venues',
        data: data,
      })
        .then(data => {
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let errorInfo = response.data.errors;
          commit('errorInfo', errorInfo);
          commit('setLoading');
          return response;
        });
    },
    changeItemToDB({ commit }, data) {
      commit('setLoading');
      return axios({
        method: 'PUT',
        url: '/venues',
        data: data,
      })
        .then(data => {
          store.commit('app/setCurrentVenue', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    deleteItem({ commit }, id) {
      commit('setLoading');
      return axios
        .delete(`/venues/${id}`)
        .then(data => {
          commit('fetchDelete', id);
          commit('setLoading');
          return data;
        })
        .catch(data => {
          commit('errorInfo', data);
          commit('setLoading');
          return data;
        });
    },
    searchVenues({ commit }, url) {
      commit('setLoading');
      return axios
        .get(url)
        .then(({ data }) => {
          commit('setList', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    checkConnection({ commit }, data) {
      return axios({
        method: 'PATCH',
        url: '/venues/check-connection',
        data: {
          venueId: data.id,
          instanceId: data.instanceId,
          distributorId: data.distributorId,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          return error.alert[0];
        });
    },
    changeStatus({ commit }, data) {
      let reqData = data;
      let statusToChangeTo = reqData.currentStatus ? 'Offline' : 'Online';
      let urlToSend = `/venues/${reqData.venueId}/${statusToChangeTo}`;
      commit('setLoading');
      return axios({
        method: 'PATCH',
        url: urlToSend,
      })
        .then(({ data }) => {
          commit('editVenueProp', reqData);
          commit('checkAndEditVenue', {
            venueId: reqData.venueId,
            field: 'status',
            value: reqData.currentStatus,
          });
          store.commit('app/checkAndEditCurrentVenue', reqData);
          commit('setLoading');
          return data;
        })
        .catch(dataCatch => {
          // let error = response.data.errors
          commit('errorInfo', dataCatch);
          commit('setLoading');
          return dataCatch;
        });
    },
    changeAvailability({ commit }, data) {
      commit('setLoading');
      return axios({
        method: 'PATCH',
        url: '/venues/availability',
        data: data,
      })
        .then(({ data }) => {
          commit('setLoading');
          return data;
        })
        .catch(dataCatch => {
          commit('errorInfo', dataCatch);
          commit('setLoading');
          return dataCatch;
        });
    },
    changeSchedule({ commit }, data) {
      let reqData = data;
      let availability = [];
      for (let i of data.schedule) {
        availability.push({
          opening_day: i.opening_day,
          opening_time: convertTimeFromAmpm(i.opening_time),
          closing_day: i.closing_day,
          closing_time: convertTimeFromAmpm(i.closing_time),
        });
      }
      commit('setLoading');
      return axios({
        method: 'PATCH',
        url: `/venues/${data.venueId}/schedule`,
        data: { availability },
      })
        .then(() => {
          commit('checkAndEditVenue', {
            venueId: reqData.venueId,
            field: 'schedule',
            value: reqData.schedule,
          });
          store.commit('app/checkAndEditCurrentVenue', reqData);
          commit('setLoading');
          this._vm.$toast.success('The schedule successfully updated');
          return true;
        })
        .catch(dataCatch => {
          commit('errorInfo', dataCatch);
          commit('setLoading');
          return dataCatch;
        });
    },
    fetchLanguages({ commit }) {
      commit('setLoading');
      return axios
        .get('/Languages')
        .then(({ data }) => {
          commit('setLanguages', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    fetchDeliveryMethods({ commit }) {
      commit('setLoading');
      return axios
        .get('/delivery-methods')
        .then(({ data }) => {
          commit('setDeliveryMethods', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    fetchIdNames({ commit }, url = `/venues/id-name`) {
      commit('setLoading');
      return axios
        .get(url)
        .then(({ data }) => {
          commit('setIdNames', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response?.data?.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error.alert[0];
        });
    },
    checkAggregatorStatus({ commit }, { url, params }) {
      commit('setLoading');
      return axios
        .get(url, { params })
        .then(({ data }) => {
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error['Alert'][0];
        });
    },
    fetchLicensesList({ commit }, filters = {}) {
      commit('setLoading');

      const params = new URLSearchParams();
      if (filters.venueId) params.append('venueId', filters.venueId);
      if (filters.areaId) params.append('areaId', filters.areaId);
      if (filters.venueGroupId) params.append('groupId', filters.venueGroupId);
      if (filters.companyName) params.append('companyName', filters.companyName);
      if (filters.woltLicenseStatus) params.append('woltLicenseStatus', filters.woltLicenseStatus);
      if (filters.boltLicenseStatus) params.append('boltLicenseStatus', filters.boltLicenseStatus);
      if (filters.fudyLicenseStatus) params.append('fudyLicenseStatus', filters.fudyLicenseStatus);
      if (filters.glovoLicenseStatus) params.append('glovoLicenseStatus', filters.glovoLicenseStatus);
      if (filters.actionPeriodStart) params.append('actionPeriodStart', filters.actionPeriodStart);
      if (filters.actionPeriodEnd) params.append('actionPeriodEnd', filters.actionPeriodEnd);

      return axios
        .get(`/venues/licences?${params.toString()}`)
        .then(({ data }) => {
          commit('setLicensesList', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error['Alert'][0];
        });
    },
    fetchLicenseActions({ commit }, venueId) {
      commit('setLoading');

      const params = new URLSearchParams();
      if (venueId) params.append('venueId', venueId);

      return axios
        .get(`/venues/actions?${params.toString()}`)
        .then(({ data }) => {
          commit('setLicenseActions', data);
          commit('setLoading');
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoading');
          return error['Alert'][0];
        });
    },
    fetchActualVenues({ commit }, venueId) {
      commit('setLoadingBoolean', true);
      return axios
        .get(`/Menu/LastExcludedItems?venueId=${venueId}`)
        .then(({ data }) => {
          commit('setLoadingBoolean', false);
          return data;
        })
        .catch(({ response }) => {
          let error = response.data.errors;
          commit('errorInfo', error);
          commit('setLoadingBoolean', false);
          return error.alert[0];
        });
    },
  },
  getters: {
    getData(state) {
      return state.list;
    },
    getLastUpdateTime(state) {
      return state.lastUpdateTime;
    },
    getItem(state) {
      return state.item;
    },
    isLoading(state) {
      return state.loading;
    },
    getActiveVenueId(state) {
      return state.activeVenueId;
    },
    getSearch(state) {
      return state.search;
    },
    getLicensesList(state) {
      return state.licensesList;
    },
    getLicenseActions(state) {
      return state.licenseActions;
    },
    getLanguages(state) {
      return state.languages;
    },
    getDeliveryMethods(state) {
      return state.deliveryMethods;
    },
    getIdNames(state) {
      return state.idNames;
    },
    getMenu(state) {
      const { woltsInCorrectDiscounts, ...rest } = state.menu;
      return rest;
    },
    getWoltsInCorrectDiscounts(state) {
      return state.woltsInCorrectDiscounts;
    },
  },
};
